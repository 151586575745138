import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SignoutService {

  public signoutSubject$: Subject<void> = new Subject<void>();
  public isSigningOut = false;

  public next(): void {
    this.isSigningOut = true;
    this.signoutSubject$.next();
  }

  public complete(): void {
    this.signoutSubject$.complete();
  }

}
