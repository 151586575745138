<ion-app dir="ltr">
  <ion-split-pane when="(min-width: 1130px)" content-id="main-content">
    <ion-menu class="app-sidemenu" contentId="main-content" (ionDidOpen)="updateMenuState($event)"
      (ionDidClose)="updateMenuState($event)">
      <ion-header>
        <ion-toolbar>
          <ion-row class="logo-wrapper">
            <ion-col size="6" offset="3">
              <div class="logo">
                <img class="sprk-logo" src="assets/logos/dark-256.png" sizes="auto" alt="Sprk Logo">
              </div>
            </ion-col>
            <ion-col class="close-wrapper" size="2" offset="1">
              <ion-menu-toggle>
                <ion-button class="close-button" fill="clear">
                  <ion-icon slot="icon-only" name="close-outline"></ion-icon>
                </ion-button>
              </ion-menu-toggle>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="8" offset="2">
              <div class="app-info-wrapper">
                <h6 class="app-version">Version {{ version }} {{ env.dev ? 'DEV' : '' }}
                </h6>
                <h6 class="app-copyright">© Copyright {{ dt.currentYear }}. All rights reserved.</h6>
              </div>
            </ion-col>
          </ion-row>
        </ion-toolbar>
      </ion-header>

      <ion-content>
        <!-- Navigation for dashboard pages -->
        <div *ngIf="!util.device.smallerThanLaptop">
          <ion-item-divider></ion-item-divider>
          <ion-list>
            <ion-menu-toggle auto-hide="false">
              <ion-item (click)="routerLink('/app/insights')">
                <ion-img class="tab-icon" slot="start" src="assets/icons/tabs/insights.png"></ion-img>
                <ion-label>Insights</ion-label>
              </ion-item>
              <ion-item (click)="routerLink('/app/dashboard')">
                <app-calendar-icon slot="start"></app-calendar-icon>
                <ion-label>Dashboard</ion-label>
              </ion-item>
              <ion-item (click)="routerLink('/app/trends')">
                <ion-img class="tab-icon" slot="start" src="assets/icons/tabs/trends.png"></ion-img>
                <ion-label>Trends</ion-label>
              </ion-item>
            </ion-menu-toggle>
          </ion-list>
        </div>

        <ion-list>
          <ion-item-divider [class]="util.device.smallerThanLaptop ? 'first-divider' : ''"></ion-item-divider>
          <ion-menu-toggle auto-hide="false">
            <ion-item (click)="routerLink('/configure')" [disabled]="!active.isConnected">
              <ion-icon class="configure-icon" slot="start" name="build-outline"></ion-icon>
              <ion-label>Customize Insights</ion-label>
            </ion-item>
            <ion-item (click)="routerLink('/settings')" [disabled]="!active.isConnected">
              <ion-icon slot="start" name="location-outline"></ion-icon>
              <ion-label>
                Locations / Connections
              </ion-label>
            </ion-item>
            <ion-item (click)="routerLink('/catalog')" [disabled]="!active.isConnected">
              <ion-icon slot="start" name="pricetags-outline"></ion-icon>
              <ion-label>Categories / Products</ion-label>
            </ion-item>
            <ion-item (click)="routerLink('/profit')" [disabled]="!active.isConnected">
              <ion-icon slot="start" name="cash-outline"></ion-icon>
              <ion-label>Profitability Analysis</ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>

        <!-- Navigation for account/preferences/signout activities -->
        <ion-list>
          <ion-item-divider></ion-item-divider>
          <ion-menu-toggle auto-hide="false">
            <ion-item (click)="routerLink('/billing')" [disabled]="!active.isConnected">
              <ion-icon slot="start" name="person-circle-outline"></ion-icon>
              <ion-label>Profile & Billing</ion-label>
            </ion-item>
            <ion-item (click)="routerLink('/preferences')" [disabled]="!active.isConnected">
              <ion-icon slot="start" name="settings-outline"></ion-icon>
              <ion-label>Preferences</ion-label>
            </ion-item>
            <ion-item (click)="signOut()">
              <ion-icon slot="start" name="log-out-outline"></ion-icon>
              <ion-label>Sign Out</ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>

        <!-- Navigation for support-related pages -->
        <ion-list>
          <ion-item-divider></ion-item-divider>
          <ion-menu-toggle auto-hide="false">
            <ion-item *ngIf="util.device.mobile && !a2hs.isInstalled" (click)="a2hs.showInstallPrompt()">
              <ion-icon slot="start" name="share-outline"></ion-icon>
              <ion-label>Add App to Home Screen</ion-label>
            </ion-item>
            <ion-item *ngIf="update.updateAvailable" (click)="update.reload()" [disabled]="!active.isConnected">
              <ion-icon slot="start" name="refresh-outline"></ion-icon>
              <ion-label>Update to latest version</ion-label>
            </ion-item>
            <ion-item (click)="routerLink('/releases')">
              <ion-icon slot="start" name="newspaper-outline"></ion-icon>
              <ion-label>What's New?</ion-label>
            </ion-item>
            <ion-item (click)="routerLink('/help')" [disabled]="!active.isConnected">
              <ion-icon slot="start" name="help-circle-outline"></ion-icon>
              <ion-label>Help</ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>

      </ion-content>
    </ion-menu>
    <!-- Main app content get's rendered in this router-outlet -->
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
</ion-app>