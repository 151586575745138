import { Injectable, OnDestroy } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';

import { BehaviorSubject, from, interval, Subject } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';

import { SignoutService } from '@services/signout.service';

@Injectable({
  providedIn: 'root',
})
export class UpdateService implements OnDestroy {
  public updateAvailableSubject$ = new BehaviorSubject<boolean>(false);
  public updateAvailable = false;

  private destroy$ = new Subject<void>();

  constructor(
    private signout: SignoutService,
    private updates: SwUpdate,
  ) { }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public initUpdates(): void {
    if (this.updates.isEnabled) {
      const everyMinutes$ = interval(30 * 1000);
      everyMinutes$
        .pipe(
          takeUntil(this.destroy$),
          takeUntil(this.signout.signoutSubject$),
          switchMap(() => from(this.updates.checkForUpdate()))
        )
        .subscribe();

      this.updates.versionUpdates
        .pipe(
          takeUntil(this.destroy$),
          takeUntil(this.signout.signoutSubject$),
          filter((event): event is VersionReadyEvent => event.type === 'VERSION_READY'),
        )
        .subscribe(() => {
          this.updateAvailable = true;
          this.updateAvailableSubject$.next(true);
        });
    }

    // If the user is in an unrecoverable state, force a reload
    this.updates.unrecoverable
      .pipe(takeUntil(this.destroy$), takeUntil(this.signout.signoutSubject$))
      .subscribe(() => {
        console.warn('APP update.initUpdates: Unrecoverable error; reloading app');
        window.location.reload();
      });
  }

  public async reload(): Promise<void> {
    this.updateAvailable = false;
    await this.updates.activateUpdate();
    window.location.reload();
  }
}
