// Angular imports
import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, Injectable, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';

// Firebase imports
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireAnalyticsModule, UserTrackingService, APP_VERSION } from '@angular/fire/compat/analytics';
import { AngularFirePerformanceModule, PerformanceMonitoringService } from '@angular/fire/compat/performance';

// Ionic imports
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

// Environment imports
import { environment } from '@environments/environment';

// App component imports
import { AppRoutingModule } from '@app/app-routing.module';
import { AppComponent } from '@app/app.component';

// Sentry component imports
import * as Sentry from '@sentry/browser';
import { CaptureConsole } from '@sentry/integrations';

// Pipe imports
import { PipesModule } from '@pipes/pipes.module';

// Service imports
import { EnvService } from '@services/env.service';
import { ErrorService } from '@services/error.service';

// Component imports
import { IonComponentsModule } from '@ion-components/ion-components.module';

@Injectable({
  providedIn: 'root',
})
export class SentryErrorHandler implements ErrorHandler {
  constructor(
    private env: EnvService,
    private error: ErrorService,
  ) {
    if (env.useSentry) {
      Sentry.init({
        dsn: 'https://baf32edb1fae449b941f43188d6ac0f8@o413292.ingest.sentry.io/5298407',
        integrations: [
          new Sentry.Integrations.TryCatch({ XMLHttpRequest: false }),
          new CaptureConsole({ levels: ['error'] }),
        ],
        environment: this.env.name,
        ignoreErrors: this.error.toIgnore(),
      });

      Sentry.configureScope(scope => {
        scope.setTag('version', this.env.version);
      });
    }
  }

  public async handleError(error: Error): Promise<void> {
    if (this.env.test) {
      this.error.handlerCompleteSubject$.next(true);
      console.error(error);
    } else {
      await this.error.handleError(error);
    }
  }
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireAnalyticsModule,
    AngularFirePerformanceModule,
    HttpClientModule,
    IonicModule.forRoot(),
    PipesModule,
    IonComponentsModule,
  ],
  providers: [
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: APP_VERSION, useValue: environment.version },
    AngularFirestoreModule,
    AngularFireAuthModule,
    UserTrackingService,
    PerformanceMonitoringService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
