import { Injectable } from '@angular/core';

import { DtService } from '@services/dt.service';
import { FmtService } from '@services/fmt.service';
import { UtilitiesService } from '@services/utilities.service';

import { LocationSettings } from '@shared/settings.interface';

@Injectable({
  providedIn: 'root',
})
export class TodayState {
  public locationIDs: string[] = [];
  public locationNames: { [key: string]: string } = {};
  public locationNamesArray: string[] = [];
  private locationMinDatesArray: { locationID: string, minDate: string }[] = [];
  public oldestMinDate = this.dt.nowStr;

  public rangeID = 'today';
  public todayRange = '';
  public todayStartDate = '';
  public todayEndDate = '';
  public todayDatesInRange: string[] = [];
  public todayIndex = '';
  public todayDateStr = '';
  public startDateAsDate: Date | undefined;
  public includesToday = true;
  public includesForecast = false;

  public compRange = '';
  public compStartDate = '';
  public compEndDate = '';
  public compDatesInRange: string[] = [];
  public compIndex = '';
  public compDateStr = '';
  public comparisonDayID = '';
  public comparisonDayIndex = 0;

  public hoursMax = 32;

  public autoScrollToNow = true;

  constructor(
    private dt: DtService,
    private fmt: FmtService,
    private util: UtilitiesService,
  ) { }


  public updateLocationIDs(locationIDs: string[], activeLocations: LocationSettings[]): void {
    // Make sure locationIDs are always in the same order
    this.locationIDs = locationIDs.sort((a, b) => a.localeCompare(b));
    this.locationNamesArray = [];
    for (const location of activeLocations) {
      if (this.locationIDs.includes(location.locationID)) {
        this.locationNamesArray.push(location.locationName ?? '');  // Sorted in default order
        this.locationMinDatesArray.push({ locationID: location.locationID, minDate: location.minDate ?? this.dt.nowStr });
      }
    }

    this.oldestMinDate = this.locationMinDatesArray
      .filter(location => this.locationIDs.includes(location.locationID))
      .map(location => location.minDate)
      .reduce((oldest, current) => oldest < current ? oldest : current, this.dt.nowStr);

    for (const locationID of locationIDs) {
      this.locationNames[locationID] =
        activeLocations.find(location => location.locationID === locationID)?.locationName ?? '';  // Sorted alphabetically
    }
    this.updateTodayIndexes();
  }

  public setTodayRange(startDate: string, endDate: string): void {
    const newTodayRange = !endDate || startDate === endDate ? startDate : `${startDate}~${endDate}`;
    if (this.todayRange !== newTodayRange) {
      this.todayRange = newTodayRange;
      this.todayStartDate = newTodayRange.split('~')[0];
      this.todayEndDate = newTodayRange.split('~')[1] || this.todayStartDate;
      this.todayDatesInRange = this.dt.datesInRange(`${startDate}~${endDate}`);
      this.updateTodayIndexes();
      // Setting to noon to avoid timezone issues; updateTimezone hasn't always been called before this function
      this.startDateAsDate = this.dt.newDate(startDate + 'T12:00:00');
      this.includesToday = this.dt.includesToday(this.todayRange);
      this.includesForecast = this.todayDatesInRange.some(date =>
        date >= this.dt.nowStr && date <= this.dt.maxDate);

      let label = this.fmt.dateRange(this.todayStartDate, this.todayEndDate, false, true);
      if (label.length > 20 && this.util.device.mobile) {
        label = this.fmt.dateRange(this.todayStartDate, this.todayEndDate, false, true, true);
      }
      this.todayDateStr = label;
    }
  }

  public setCompRange(startDate: string, endDate: string, name: string): void {
    const newCompRange = !startDate ? '' : startDate === endDate ? startDate : `${startDate}~${endDate}`;
    if (this.compRange !== newCompRange) {
      this.compRange = newCompRange;
      this.compStartDate = newCompRange.split('~')[0];
      this.compEndDate = newCompRange.split('~')[1] || this.compStartDate;
      this.compDatesInRange = this.dt.datesInRange(`${startDate}~${endDate}`);
      this.updateTodayIndexes();

      if (this.comparisonDayID === 'none') {
        this.compDateStr = '';
      } else {
        let label = this.fmt.dateRange(this.compStartDate, this.compEndDate, false, true);
        if (label.length > 20 && this.util.device.mobile) {
          label = this.fmt.dateRange(this.compStartDate, this.compEndDate, false, true, true);
        }
        this.compDateStr = `${this.comparisonDayID.startsWith('event') ? `${name} (${label})` : label}`;
      }
    }
  }

  public updateTodayIndexes(): void {
    this.todayIndex = `${this.locationIDs.join('~')}|${this.todayRange}`;
    this.compIndex = `${this.locationIDs.join('~')}|${this.compRange || 'none'}`;
  }
}
