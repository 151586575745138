import { Injectable } from '@angular/core';

import { FunctionsService } from '@services/functions.service';

import { RequestHTMLEmail } from '@shared/request.interface';

import { AppState } from '@state/app.state';

@Injectable({
  providedIn: 'root',
})
export class OtpService {

  constructor(
    private appState: AppState,
    private functions: FunctionsService,
  ) { }

  public async getPasscode(uniqueID: string): Promise<string> {
    return await this.functions.getPasscode(uniqueID);
  }

  public async verifyPasscode(uniqueID: string, passcode: string): Promise<boolean> {
    const result = await this.functions.verifyPasscode(uniqueID, passcode);
    return result;
  }

  public async emailPasscode(email: string, passcode: string): Promise<void> {
    const html = `<p>Enter the following passcode in the Sprk™ app to verify your email address ` +
      `(${email}):</p>` +
      `<p><font style="color: #1DD699; font-size: 3rem; letter-spacing: 0.5rem">` +
      `${passcode}` +
      `</font></p>` +
      `<p>This passcode will expire in 30 minutes.</p>` +
      `<p>If you didn’t ask to verify this email address, you can ignore this email.</p>` +
      `<p>Thanks,</p>` +
      `<p>Sprk™ team</p>`;

    const message: RequestHTMLEmail = {
      clientID: this.appState.clientID,
      locationID: this.appState.locationID,
      to: email,
      subject: 'Verify email for Sprk™ app',
      html: html,
      secret: `8a20e3b4-a73e-482b-b331-fd5bea4604bf`,
    };
    await this.functions.sendEmail(message);
  }

}
