import { Injectable } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';

import { User } from '@firebase/auth-types';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {

  constructor(
    private analytics: AngularFireAnalytics,
  ) { }

  public async login(user: User): Promise<void> {
    if (user) {
      await this.analytics.setUserProperties({
        UID: user.uid,
      });
    }
    await this.analytics.logEvent('login', {});  // purposely skipping await
  }
}
